import { CanActivateFn } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { inject } from "@angular/core";
import { UserRole } from "../models/user";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs";

export const hasRoles: CanActivateFn = (route) => {
    let roles = ((route.data as any)?.hasRoles ?? []) as UserRole[];
    const auth = inject(AuthService);
    return auth.loginCompleted$.pipe(
        filter((el) => !!el),
        distinctUntilChanged(),
        switchMap(() =>
            auth.roles$.pipe(
                map((userRoles) =>
                    roles.some((role) => userRoles.includes(role))
                )
            )
        )
    );
};
