import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { distinctUntilChanged, filter, map, switchMap } from "rxjs";
import { UserRole } from "../models/user";
import { AuthService } from "../services/auth.service";

export const redirectManager: CanActivateFn = (route) => {
    let roles = ((route.data as any)?.redirectManager as
        | UserRole[]
        | undefined) ?? [
        UserRole.ADMIN,
        UserRole.HQ,
        UserRole.MARKENTING_MANAGER,
        UserRole.SUPER_MARKET_MANAGER
    ];
    const router = inject(Router);
    const auth = inject(AuthService);
    return auth.loginCompleted$.pipe(
        filter((el) => !!el),
        distinctUntilChanged(),
        switchMap(() =>
            auth.roles$.pipe(
                map((userRoles) =>
                    roles.some((role) => userRoles.includes(role))
                ),
                map((isManager) =>
                    isManager ? router.createUrlTree(["dealers"]) : true
                )
            )
        )
    );
};
